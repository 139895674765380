// 
// page: bio
// 

.section-bio{
    .section-image{
        transform: translateY(-10%);
    }

    .section-content{
        padding-bottom: 0;
    }
    
    .bio-instagram{
        background-color: $black;
        margin: 120px 0px 0px 0px;
        padding: 170px 5% 130px 5%;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: -30vw;
            top: 0;
            bottom: 0;
            right: -90%;
            z-index: -1;
            background-color: $black;
        }
    }
    .mansory-container{
        .mansory-item, .grid-sizer{
            width: 25%;
        }
    }
}


@include media-breakpoint-down(xl) {
    .section-bio{
        padding-top: 3%;
        .section-lead-left{
            padding-left: 0;
            text-align: left;
            padding-bottom: 5%;
        }
        .bio-instagram{
            margin: 50px 0px 0px 0px;
            padding: 100px 5% 100px 5%;
        }
    }
}


@include media-breakpoint-down(lg) {
    .section-bio{
        padding-top: 0;
        .section-content{
            padding-top: 0;
        }

        .bio-instagram{
            margin: 25px 0px 0px 0px;
            padding: 50px 0px;
            &:before{
                top: 0;
                bottom: 0;
            }
        }
        .section-image{
            transform: none;
        }

        
    }
}

@include media-breakpoint-down(sm) {
    .section-bio{
        .mansory-container{
            .mansory-item, .grid-sizer{
                width: 50%;
            }
        }
    }
}