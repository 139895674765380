// 
// page: team
//

.section-team{
    padding: 60px;
    position: relative;
    .section-team-bg{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: 50%;
    }
}

.mansory-team-container{
    &:after{
        content: '';
        display: block;
        clear: both;
    }

    .mansory-item{
        float: left;
        padding: 0;
    }
    .mansory-item, .grid-sizer{
        width: 33.333%;
    }
    .mansory-item:nth-child(3){
        padding-top: 130px;
    }
    .mansory-item:nth-child(4){
        padding-top: 260px;
    }
}

.team-item{
    display: flex;
    position: relative;
    padding-bottom: 30%;
    box-sizing: border-box;
    .image{
        flex: 0 0 45%;
        align-self: flex-start;
        img{
            display: block;
            width: 100%;
        }
    }
    .content{
        flex: 0 0 55%;
        max-width: 55%;
        padding: 0 20px;
        box-sizing: border-box;
        position: relative;
    }

    .title{
        .h3{
            text-transform: uppercase;
            font-weight: 700;
        }
        .meta{
            font-family: 'Oswald', sans-serif;
            font-size: 16px;
            font-weight: 300;
            text-transform: uppercase;
        }
    }

    .team-btn{
        position: absolute;
        bottom: 0;
        z-index: 20;
        transform: translate(-50%, 50%);
    }
}


@include media-breakpoint-down(xl) {
    .section-team{
        padding: 40px 20px;
        .content p{
            font-size: 13px;
        }
    }

    .mansory-team-container{
        .mansory-item, .grid-sizer{
            width: 50%;
        }
        .mansory-item:nth-child(1), .mansory-item:nth-child(2), .mansory-item:nth-child(3), .mansory-item:nth-child(4){
            padding: 0;
        }
        .mansory-item:nth-child(3){
            padding-top: 100px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .team-item{
        .team-btn{
            position: static;
            margin-top: 20px;
            transform: translate(-50%, 50%);
        }
    }
}

@include media-breakpoint-down(md) {
    .mansory-team-container{
        .mansory-item, .grid-sizer{
            width: 100%;
        }

        .mansory-item{
            padding-top: 0;
        }

        .mansory-item:nth-child(1), .mansory-item:nth-child(2), .mansory-item:nth-child(3), .mansory-item:nth-child(4) {
            padding-top: 0px;
        }
    }

    .team-item {
        padding-bottom: 20px;
        .team-btn{
            transform: none!important;
            margin-top: 30px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .team-item {
        display: block;
        .image, .content{
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        .content{
            padding: 40px 0;
            p{
                font-size: 14px;
            }
        }
    }
}

