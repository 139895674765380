// 
// page: home
// 

// hero
.page-home{
    .hero{
        &-image{
            padding-bottom: 140%;
        }
        &-mark{
            position: absolute;
            left: 0%;
            bottom: auto;
            top: 0;
            width: 48%;
            img{
                display: block;
                width: 100%;
                max-width: 545px;
            }
        }
        &-subtitle{
            bottom: -10%;
        }
        &-scroll{
            top: 35%;
        }
    }
}
// section video
.video-container{
    position: relative;
    padding-left: 40%;
    transform: translateY(-50%);
    margin-bottom: -15%;
    &:after{
        //content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 50%;
        background-color: $black;
        z-index: -1;
    }
    .video-title{
        position: absolute;
        top: 30%;
        left: 40%;
        color: $white;
        font-family: 'Oswald', sans-serif;
        font-size: 24px;
        font-weight: 300;
        transform: translateX(-120%);
        text-transform: uppercase;
        span{
            color: $secoundary;
            font-weight: 700;
            position: relative;
            margin-left: 10px;
            &:after{
                content: "";
                position: absolute;
                top: -25px;
                left: 0;
                width: 300px;
                height: 1px;
                background-color: $gray-300;
            }
        }
    }
}
// section-home-about
.section-home-about{
    position: relative;

    .col-about-image{
        position: relative;
        .flower{
            position: absolute;
            top: -90%;
            left: 0;
            right: 0;
            z-index: 22;
            //transform: translateX(-50%);
            img{
                display: block;
                width: 100%;
            }
        }
    }
    .about-container{
        display: flex;
        flex-wrap: wrap;
        max-width: 810px;
        .about-box{
            flex: 0 0 50%;
            //max-width: 270px
            padding-left: 20px;
            padding-right: 120px;
            position: relative;
            .about-meta{
                font-family: 'Oswald', sans-serif;
                font-size: 43px;
                font-weight: 300;
                position: relative;
                &:after{
                    content: "";
                    position: absolute;
                    left: -45px;
                    top: -10px;
                    width: 50px;
                    height: 2px;
                    background-color: $black;
                    transform: rotate(-45deg);
                }
            }
            .about-title{
                text-transform: uppercase;
                font-weight: 600;
            }

            .about-box-btn{
                position: absolute;
                bottom: 20px;
                right: -60px;
            }
        }

        .about-box:nth-child(2){
            padding-top: 10%;
            margin-bottom: 10%;
        }
        .about-box:nth-child(3){
            padding-left: 85px;
            padding-right: 40px;
        }

        .about-box:nth-child(4){
            padding-right: 30px;
        }
    }
}

// section-home-team
.section-home-team{
    position: relative;
    .home-team-bg{
        position: absolute;
        top: 0;
        bottom: 0%;
        left: 0;
        width: 60%;
        .bg-color, .bg-image, .bg-mask{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .bg-color{
            background-color: $black;
            top: 0;
            bottom: 0%;
        }
        
        .bg-image{
            background-size: cover;
            background-position: top center;
        }
        .bg-mask{
            background-color: rgba($black, 0.4);
        }
        .bg-gradient{
            position: absolute;
            left: 0;
            right: 0;
            top: 70%;
            bottom: -20%;
            background: linear-gradient(to bottom, transparent 0%,rgba($black, 0.5) 17%, $black 100%);
            
        }
        .bg-slice{
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            background-color: $white;
        }

        .bg-scroll{
            position: absolute;
            right: 45%;
            top: 75%;
            .scroll-arrow{
                .arrow-title{
                    color: $white;
                    font-size: 24px;
                    transition: all 0.3s;
                }
                &:hover .arrow-title{
                    color: $secoundary;
                }
            }
        }
    }
    .mansory-container{
        .mansory-item, .grid-sizer{
            width: 50%;
        }
    }

    .instagram-mansory{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 80px 10px;
    }

    .section-content{
        padding: 40% 0% 60% 0%;
        text-align: right;

        .h1, .h3{
            margin: 0;
            line-height: 1;
        }
        .h3{
            text-transform: uppercase;
            padding-left: 55px;
            font-weight: 300;
            margin-bottom: 40px;
        }

        .btn{
            margin-top: 60px;
        }
    }
}


// section home blog
.section-home-blog{
    position: relative;
    padding: 5% 0 5% 0;

    .blog-container{
        display: block;
        width: 100%;
    }

    .blog-item{
        .blog-item-content{
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: flex-end;
            pointer-events: none;
            padding: 0px!important;
        }
        .title a, .meta, p{
            color: $white;
        }
        .meta{
            color: $secoundary!important;
        }
    }

    .section-bg{
        top: 30%;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .pager-container{
        position: absolute;
        z-index: 33;
        right: 14%;
        bottom: 15%;
        .pager-back{
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }

    // blog items
    .blog-item{
        .meta, .title a{
            color: $white;
        }
        .blog-item-content{
            p{
                color: rgba($white, 0.6);
            }
        }
    }

    ul.blog-list{
        > li:nth-child(5n+1){
            flex: 0 0 30%;
        }
        > li:nth-child(5n+2){
            flex: 0 0 25%;
            margin-left: 7%;
            .blog-item{
                transform: translateY(-5%);
            }
        }
    
        > li:nth-child(5n+3){
            margin-left: 7%;
            flex: 0 0 18%;
            
        }
    }

    .swiper-slide{
        ul.blog-list > li{
            opacity: 0;
            transform: translateX(10%);
            transition: all 0.3s;
        }

        ul.blog-list > li:nth-child(1){
            transition-delay: 0.45s;
        }
        ul.blog-list > li:nth-child(2){
            transition-delay: 0.65s;
        }
        ul.blog-list > li:nth-child(3){
            transition-delay: 0.85s;
        }
    }

    .swiper-slide-active{
        ul.blog-list > li{
            opacity: 1;
            transform: translateX(0%);
        }
    }
}

// section-home-before-after
.section-home-before-after{
    padding-top: 0px;
    text-align: center;
    .section-lead{
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }
    .h5{
        margin-top: 30px;
    }

    .col-image{
        text-align: center;
        align-self: center;
    }
}

.section-home-after{
    p{
        margin-bottom: 30px;
    }
    .btn{
        margin-top: 60px;
    }
}

@include media-breakpoint-down(xl) {
    // section home about
    .section-home-about {
        .about-container .about-box .about-box-btn{
            right: 0px;
        }
    }
    // section home team
    .section-home-team{
        .home-team-bg .bg-scroll{
            top: 85%;
        }
    }

    .page-home{
        .hero{
            .hero-image {
                padding-bottom: 125%;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    // section home blog
    .section-home-blog {
        ul.blog-list > li:nth-child(5n+3){
            p{
                display: none;
            }
        }
    }

    .page-home .hero-mark{
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .page-home .hero-subtitle{
        bottom: 0;
    }
    .page-home .hero-image{
        padding-bottom: 100%;
    }

    // section home about
    .section-home-about{
        padding-bottom: 0;
        .flower{
            display: none;
        }
        .home-about-slice{
            display: none;
        }

        .about-container{
            padding: 40px 5%;
            padding-bottom: 0;
            display: block;
            .about-box{
                padding: 0px 0px 25px 40px;
            }
            .about-box:nth-child(3){
                padding-left: 40px;
            }

            .about-box .about-box-btn{
                bottom: 0px;
                right: 0px;
            }
        }
    }

    

    // video
    .video-container{
        margin-top: 10px;
        transform: translateY(0px);
        background-color: $dark;
        padding-left: 0;
        .video-title{
            position: static;
            transform: translateX(0px);
            padding: 5%;
            span:after{
                content: none;
            }
        }
    }
    
    // section home team
    .section-home-team {
        .section-content{
            padding: 40px 5%;
        }
        .home-team-bg{
            display: none;
        }
    }

    // section home blog
    .section-home-blog {
        .section-bg, .pager-container{
            display: none;
        }
        .section-title{
            padding-left: 5%;
        }
        ul.blog-list{
            display: block;
            > li, > li:nth-child(5n+1), > li:nth-child(5n+2), > li:nth-child(5n+3), > li:nth-child(5n+4), > li:nth-child(5n+5){
                display: block;
                flex: 0 0 100%;
                max-width: 100%;
                margin: 5% 0;
                .blog-item{
                    transform: none!important;
                    .blog-item-content{
                        pointer-events: all;
                        position: static;
                        padding: 0px;
                        p{
                            display: block;
                        }
                        .title a, .meta, p{
                            color: $black;
                        }
                    }
    
                    .blog-item-image{
                        .image-holder{
                            padding-bottom: 80%;
                        }
                    }
                }
            }
        }
    }
}