//
// component: hero
//

.hero{
    position: relative;
    &-scroll{
        position: absolute;
        top: 45%;
        right: 60px;
        .scroll-arrow{
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }
    &-brand{
        padding: 90px 0px 120px 0px;
        margin-left: -18%;
    }
    &-title{
        text-transform: uppercase;
        font-weight: 700;
        margin: 30px 0px 60px 0px;
        padding-right: 120px;
        position: relative;
        .scroll-arrow-div{
            position: absolute;
            right: 30px;
            top: 100px;
        }
    }
    &-image{
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;
    }
    
    &-bg, &-bg-mask{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    &-bg{
        background-size: cover;
        background-position: top center;
        filter: grayscale(1);
    }
    &-bg-mask{
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,1) 100%); 
    }
    &-subtitle{
        position: absolute;
        left: -38px;
        bottom: 0%;
        transform: rotate(-90deg);
        transform-origin: top left;
        .h1{
            font-size: 200px;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
        }
    }

    &-mark{
        position: absolute;
        left: 20%;
        bottom: 19%;
        width: 48%;
        max-width: 40%;
        img{
            display: block;
            width: 100%;
        }
    }

    &-content{
        padding-right: 90px;
    }

    .hero-menu{
        padding-left: 15%;
    }

    ul.hero-menu-list{
        font-family: 'Oswald', sans-serif;
        margin: 0;
        padding: 0;

        li{
            margin-bottom: 12px;
        }
        
        > li a{
            font-size: 30px;
            font-weight: 600;
            color: $black;
            text-transform: uppercase;
            position: relative;
            padding-left: 75px;
            transition: all 0.3s;

            .arrow{
                position: absolute;
                left: 0;
                top: 15px;
                opacity: 0;
                transform: translateX(-15px);
                transition: all 0.3s;
            }
            &:hover{
                color: $secoundary;
                .arrow{
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
        }
    }
}

// aos
[data-aos="hero-animation"] {
    @media screen and (min-width: 992px) {
        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $white;
            z-index: 88;
            transition: all 1.25s 0.55s;
        }

        .hero-brand{
            opacity: 0;
            transform: translateX(-50px);
            transition: all 0.35s 1s;
        }
        .breadcrumb, .hero-title, .hero-menu, .hero-content{
            opacity: 0;
            transform: translateX(-50px);
            transition: all 0.3s 1.25s;
        }
        .scroll-arrow-div{
            opacity: 0;
            transform: translateY(50px);
            transition: all 0.3s 1.75s;
        }
        
        &.aos-animate {
            &:after{
                transform: translateX(110%);
            }
            .hero-brand{
                opacity: 1;
                transform: translateX(0px);
            }
            .breadcrumb, .hero-title, .hero-menu, .hero-content{
                opacity: 1;
                transform: translateX(0px);
            }
            .scroll-arrow-div{
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}


@include media-breakpoint-down(xl) {
    .hero{
        &-brand{
            padding: 50px 0px 70px 0px;
            img{
                max-width: 40%;
            }
        }
        &-title{
            margin: 10px 0px 40px 0px;
            .scroll-arrow-div{
                right: 10px;
            }
        }
        &-mark{
            bottom: 30%;
        }
        .hero-subtitle {
            left: -23px;
            bottom: 10%;
            .h1{
                font-size: 125px;
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .hero{
        &-mark{
            bottom: 5%;
        }
        .hero-subtitle {
            left: -23px;
            bottom: 0%;
            .h1{
                font-size: 120px;
            }
        }
    }
}



@include media-breakpoint-down(md) {
    .row.hero{
        .col-hero-image{
            order: 1;
        }
        .col-hero-content{
            order: 2;
        }
    }

    .hero{
        .hero-brand, .breadcrumb{
            display: none;
        }
        .hero-subtitle{
            transform: rotate(0deg) translateY(16px);
            bottom: 0px;
            top: auto;
            left: 5%;
            .h1{
                font-size: 72px;
            }
        }
        .hero-mark{
            display: none;
        }
        .hero-menu{
            padding-left: 5%;
        }
        .hero-title{
            padding: 40px 0px 20px 5%;
            margin: 0;
            .scroll-arrow-div{
                display: none;
            }
        }
        ul.hero-menu-list > li a{
            font-size: 24px;
            .arrow{
                top: 12px;
                opacity: 1;
                transform: translateX(0px);
            }
        }

        .hero-scroll{
            display: none;
        }

        .hero-content{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}