//
// FORM CONTROL
//
.form-control {
    border-radius: 0px;
    font-size: 13px;
    padding: 12px 40px;
    height: 50px;
    font-weight: 300;
    color: $white;
    background-color: transparent;
    border: 1px solid $gray-300;
}

.form-control:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: $secoundary;
}

.form-control:disabled, .form-control[readonly]{
    background-color: $black;
}


// select
.custom-select{
    height: 50px;
    border-radius: 0px;
    font-size: 13px;
    border-color: $gray-300;
    font-weight: 300;
    color: $dark;
    background: url(../img/svg/down.svg) no-repeat right 0.75rem center/8px 10px;
}

.custom-select:focus{
    border-color: $secoundary;
    box-shadow: none;
}

//
// TEXTAREA
//
textarea.form-control {
    height: auto;
    line-height: 1.3;
}

//
// LABEL
//
label:not(.custom-control-label){
    font-size: 13px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba( $white, 0.6);
    padding-left: 40px;
}


//
// FORM GROUP
//
.form-group {
    position: relative;
    margin-bottom: 15px;
}


// 
// FORM ROW
// 
.form-row {
    margin-right: -10px;
    margin-left: -10px;
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

//
// PLACEHOLDERS
//
input ::-webkit-input-placeholder {
    color: rgba($black, 0.6);
}

input ::-moz-placeholder {
    color:  rgba($black, 0.6);
}

input :-ms-input-placeholder {
    color:  rgba($black, 0.6);
}

input :-moz-placeholder {
    color:  rgba($black, 0.6);
}


//
// CUSTOM RADIO, CUSTOM CHECKBOXES
//
.custom-control{
    label{
        font-size: 12px;
    }
}
.custom-checkbox{
    padding-left: 35px;
    padding-top: 10px;
    a{
        color: $secoundary;
    }
}
.custom-control-label::before{
    content: none;
}
.custom-control-label{
    color: rgba( $white, 0.6);
}
.custom-control-label::after{
    width: 20px;
    height: 21px;
    left: -35px;
    top: 0px;
    background-image: url(../img/checkbox.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background-image: url(../img/checkbox.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}

.has-danger {
    .custom-control-label::after{
        background-position: bottom center;
    }
}

// 
// bootstap select
// 
.bootstrap-select{
    > .dropdown-toggle{
        border: 1px solid $black;
        background-color: $black;
    }

    >.dropdown-toggle[aria-expanded="true"]{
        border: 1px solid $primary;
        background-color: $white;
    }

    .dropdown-toggle.bs-placeholder {
        color: $white;
    }
    
    > .dropdown-toggle.bs-placeholder:hover,  
    > .dropdown-toggle.bs-placeholder:focus,
    > .dropdown-toggle.bs-placeholder:active {
        color: $white;
    }

    .dropdown-toggle[aria-expanded="true"]{
        border: 1px solid $black;
        background-color: $black;
    }

    >.dropdown-toggle:active, >.dropdown-toggle.active{
        background-color: $black!important;
        border: 1px solid $black;
        
    }
    >.dropdown-toggle:focus{
        outline: none!important;
        box-shadow: none!important;
        border: 1px solid $primary;
    }

    .dropdown-item.active, .dropdown-item:active{
        background-color: $primary;
    }

    .dropdown-menu, .dropdown-item:last-child, .dropdown-item:first-child{
        border-radius: 0px;
    }

    .dropdown-toggle::after{
        border-top: 8px solid;
        border-right: 6px solid transparent;
        border-bottom: 0;
        border-left: 6px solid transparent;
    }
}

.bootstrap-select .dropdown-menu{
    border-radius: 0px;
    padding: 0;
    background-color: $black;
    .dropdown-item{
        padding: 8px 20px;
        font-size: 13px;
        font-weight: 500;
        color: $white;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color: $white;
        text-decoration: none;
        background-color: $secoundary;
    }
    .dropdown-item.active, .dropdown-item:active{
        background-color: $secoundary;
    }
}


//
// VALIDATION
//
.parslay-danger {
    display: none;
}

.form-control.form-control-danger {
    border-color: $danger;
}

.form-group.has-danger {
    label:before {
        border-color: $danger;
    }
}
.bootstrap-select > .dropdown-toggle.form-control-danger{
    border-color: $danger;
}

//
// [data-filter]
// 
.data-filter-group div[data-filter] {
    display: none;
}

.data-filter-group div[data-filter].active {
    display: block;
}


// 
// form wizard
// 
.form-wizzard-section.current {
    display: inherit;
}
.form-wizzard-section {
    display: none;
}


// 
// Bootstrap datapicker
// 
.input-datapicker{
    position: relative;
    &:after{
        content: "\e906";
        font-family: 'icomoon' !important;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        pointer-events: none;
    }
}

.datepicker{
    border-radius: 0px;
}

.datepicker td, .datepicker th{
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    height: 30px;
}

.datepicker table tr td.active{
    background-image: none;
    background-color: $primary!important;
}

.date-placeholder{
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 5px;
    display: none;
    left: 0px;
    top: 5px;
    position: absolute;
    left: 25px;
    top: 10px;
    pointer-events: none;
}

.datepicker-mobile{
    input[type="date"]{
        padding-top: 20px;
    }
    .date-placeholder{
        display: block;

    }
}

//
// custom File
//

.has-danger{
    .input-group{
        border-color: red;
    }
}


// file upload multiple
.custom-file-multiple{
    padding: 100px 20px;
    cursor: pointer;
    background-color: transparent;
    background-image: repeating-linear-gradient(to right, $gray-300 0%, $gray-300 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to right, $gray-300 0%, $gray-300 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, $gray-300 0%, $gray-300 50%, transparent 50%, transparent 100%), repeating-linear-gradient(to bottom, $gray-300 0%, $gray-300 50%, transparent 50%, transparent 100%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
    border:0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .custom-file-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        height: 0;
    }

    .input-file-btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    

    .label-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: $white;
        margin-bottom: 8px;
        [class^="icon"]{
            font-size: 24px;
            color: $white;
        }
    }
    .label-text{
        font-size: 12px;
        text-align: center;
        color: rgba( $white, 0.6);
        font-weight: 300;
        font-family: 'Open Sans', sans-serif;
        text-transform: none;
    }
}


@include media-breakpoint-down(md) {
    .form-group-flex {
        display: block;
    }

    .group-info{
        padding-left: 10px;
    }
}


@include media-breakpoint-down(md) { 
    .form-flex{
        display: block;
        .btn{
            margin-top: 20px;
        }
    }

    .form-group-file .custom-file{
        display: block;
    }
    .form-group-file .input-file-btn{
        justify-content: flex-end;
        padding: 15px 0;
    }

    .form-conatiner .form-title{
        padding: 20px 0;
    }

    label:not(.custom-control-label){
        padding-left: 10px;
    }
}