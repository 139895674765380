
body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: $black;
}

ul, ol{
    @extend .list-unstyled;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: 'Oswald', sans-serif;
    color: $black;
}

h1, .h1 {
    font-size: 90px;
}

h2, .h2 {
    font-size: 48px;
}

h3, .h3 {
    font-size: 43px;
}

h4, .h4 {
    font-size: 24px;
}

h5, .h5 {
    font-size: 20px;
}

h6, .h6 {
    font-size: 18px;
}


p, ul, ol{
    font-size: 14px;
}
.lead{
    font-size: 18px;
}

a,
a:hover,
a:focus,
button,
button:focus,
button:hover {
    text-decoration: none;
    outline: none;
}

a {
    transition: all 0.35s;
    color: $black;
    &:hover, &:focus{
        color: $secoundary;
    }
}

.font-light{
    font-weight: 300;
}
.font-medium{
    font-weight: 500;
}
.font-bold{
    font-weight: bold;
}
.text-primary{
    color: $primary;
}

.text-white{
    color: $white-text;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p{
        color: inherit;
    }
}

a:hover,
a:focus {
    //
}

@include media-breakpoint-down(xl) {
    h1, .h1 {
        font-size: 64px;
    }
    h3, .h3{
        font-size: 30px;
    }
}
@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size: 44px;
    }
}
