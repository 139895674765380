@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?8jk0pa');
    src: url('../fonts/icomoon.eot?8jk0pa#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?8jk0pa') format('truetype'), url('../fonts/icomoon.woff?8jk0pa') format('woff'), url('../fonts/icomoon.svg?8jk0pa#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-file-text2:before {
    content: "\e926";
}

.icon-file-picture:before {
    content: "\e927";
}

.icon-file-music:before {
    content: "\e928";
}

.icon-file-play:before {
    content: "\e929";
}

.icon-file-video:before {
    content: "\e92a";
}

.icon-file-zip:before {
    content: "\e92b";
}

.icon-copy:before {
    content: "\e92c";
}

.icon-paste:before {
    content: "\e92d";
}

.icon-mail:before {
    content: "\ea83";
}

.icon-mail2:before {
    content: "\ea84";
}

.icon-mail3:before {
    content: "\ea85";
}

.icon-mail4:before {
    content: "\ea86";
}

.icon-amazon:before {
    content: "\ea87";
}

.icon-google:before {
    content: "\ea88";
}

.icon-google2:before {
    content: "\ea89";
}

.icon-google3:before {
    content: "\ea8a";
}

.icon-google-plus:before {
    content: "\ea8b";
}

.icon-facebook1:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-instagram1:before {
    content: "\ea92";
}

.icon-whatsapp:before {
    content: "\ea93";
}

.icon-spotify:before {
    content: "\ea94";
}

.icon-telegram:before {
    content: "\ea95";
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-vine:before {
    content: "\ea97";
}

.icon-vk:before {
    content: "\ea98";
}

.icon-rss:before {
    content: "\ea9b";
}

.icon-rss2:before {
    content: "\ea9c";
}

.icon-youtube:before {
    content: "\ea9d";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-twitch:before {
    content: "\ea9f";
}

.icon-vimeo:before {
    content: "\eaa0";
}

.icon-vimeo2:before {
    content: "\eaa1";
}

.icon-lanyrd:before {
    content: "\eaa2";
}

.icon-flickr:before {
    content: "\eaa3";
}

.icon-flickr2:before {
    content: "\eaa4";
}

.icon-onedrive:before {
    content: "\eaaf";
}

.icon-github:before {
    content: "\eab0";
}

.icon-npm:before {
    content: "\eab1";
}

.icon-basecamp:before {
    content: "\eab2";
}

.icon-trello:before {
    content: "\eab3";
}

.icon-wordpress:before {
    content: "\eab4";
}

.icon-joomla:before {
    content: "\eab5";
}

.icon-ello:before {
    content: "\eab6";
}

.icon-blogger:before {
    content: "\eab7";
}

.icon-blogger2:before {
    content: "\eab8";
}

.icon-tumblr:before {
    content: "\eab9";
}

.icon-tumblr2:before {
    content: "\eaba";
}

.icon-yahoo:before {
    content: "\eabb";
}

.icon-yahoo2:before {
    content: "\eabc";
}

.icon-windows:before {
    content: "\eac1";
}

.icon-windows8:before {
    content: "\eac2";
}

.icon-soundcloud:before {
    content: "\eac3";
}

.icon-soundcloud2:before {
    content: "\eac4";
}

.icon-skype:before {
    content: "\eac5";
}

.icon-reddit:before {
    content: "\eac6";
}

.icon-hackernews:before {
    content: "\eac7";
}

.icon-wikipedia:before {
    content: "\eac8";
}

.icon-linkedin:before {
    content: "\eac9";
}

.icon-linkedin2:before {
    content: "\eaca";
}

.icon-stackoverflow:before {
    content: "\ead0";
}

.icon-pinterest:before {
    content: "\ead1";
}

.icon-pinterest2:before {
    content: "\ead2";
}

.icon-foursquare:before {
    content: "\ead6";
}

.icon-file-pdf:before {
    content: "\eadf";
}

.icon-file-openoffice:before {
    content: "\eae0";
}

.icon-file-word:before {
    content: "\eae1";
}

.icon-file-excel:before {
    content: "\eae2";
}

.icon-libreoffice:before {
    content: "\eae3";
}

.icon-arrow-down-sm:before {
    content: "\e900";
}

.icon-arrow-down:before {
    content: "\e901";
}

.icon-arrow-left-sm:before {
    content: "\e902";
}

.icon-arrow-left:before {
    content: "\e903";
}

.icon-arrow-right-sm:before {
    content: "\e904";
}

.icon-arrow-right:before {
    content: "\e905";
}

.icon-arrow-up-sm:before {
    content: "\e906";
}

.icon-arrow-up:before {
    content: "\e907";
}

.icon-burger:before {
    content: "\e908";
}

.icon-close-st:before {
    content: "\e909";
}

.icon-cross-out:before {
    content: "\e90a";
}

.icon-down-arrow:before {
    content: "\e90b";
}

.icon-down:before {
    content: "\e90c";
}

.icon-envelope:before {
    content: "\e90d";
}

.icon-facebook-logo:before {
    content: "\e90e";
}

.icon-facebook:before {
    content: "\e90f";
}

.icon-file:before {
    content: "\e910";
}

.icon-instagram-logo:before {
    content: "\e911";
}

.icon-instagram:before {
    content: "\e912";
}

.icon-magnifying-glass:before {
    content: "\e913";
}

.icon-minus:before {
    content: "\e914";
}

.icon-next:before {
    content: "\e915";
}

.icon-phone-receiver:before {
    content: "\e916";
}

.icon-play:before {
    content: "\e917";
}

.icon-plus:before {
    content: "\e918";
}

.icon-prev:before {
    content: "\e919";
}

.icon-resize:before {
    content: "\e91a";
}

.icon-tick:before {
    content: "\e91b";
}

.icon-tr-down:before {
    content: "\e91c";
}

.icon-tr-left:before {
    content: "\e91d";
}

.icon-tr-right:before {
    content: "\e91e";
}

.icon-tr-up:before {
    content: "\e91f";
}

.icon-up:before {
    content: "\e920";
}