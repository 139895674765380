
$white: #fff;
$white-text: rgba(255, 255, 255, 0.6);

$black:      #000;
$black-text: rgba(0, 0, 0, 0.6);

$dark:       #131313;
$dark-text: rgba(#131313, 0.6);

$secoundary: #f22f3d;

$gray-100:   #767373;
$gray-200:   #e5e5e5;
$gray-300:   #424242;








