// 
// component: Sidebar
// 

.sidebar{
    padding-top: 180px;
    max-width: 210px;
    .widget{
        margin-bottom: 40px;
    }
    .widget-title{
        font-family: 'Oswald', sans-serif;
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .widget-menu{
        margin-top: 10px;
        li{
            margin-bottom: 6px;
        }
        li > a {
            font-size: 14px;
        }
    }

    .widget-recent{
        margin-left: -20px;
        margin-right: -20px;
        .widget-title{
            padding-left: 20px;
        }
    }
    
    .widget-archive{
        margin-left: -20px;
        margin-right: -20px;
        .widget-title{
            padding-left: 20px;
        }
    }
    ul.recent-posts-list{
        padding: 0;
        margin: 0;
        > li{
            padding: 20px;
            border-bottom: 1px solid $gray-200;
        }
        > li:first-child{
           padding-top: 0;
        }
    }

    .recent-post{
        display: flex;
        .recent-post-image{
            flex: 0 0 60px;
            .image-holder{
                padding-bottom: 140%;
            }
        }
        .recent-post-body{
            padding: 5px 20px;
            .post-meta{
                font-size: 18px;
                font-family: 'Oswald', sans-serif;
                margin-bottom: 2px;
            }
            .post-short{
                font-size: 13px;
            }
        }
    }
}


@include media-breakpoint-down(md) {
    .sidebar{
        max-width: 100%;
        padding: 0 5%;
        .widget-recent, .widget-archive{
            margin-left: 0;
            margin-right: 0;
            .widget-title{
                padding-left: 0;
            }
        }
        ul.recent-posts-list > li{
            padding-left: 0;
        }
        .bootstrap-select{
            width: 100%!important;
        }
    }
}