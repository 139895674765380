//
// Bredcrumbs
//
.breadcrumb {
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    align-items: center;
    &-item,
    &-item a {
        font-family: inherit;
        font-size: 14px;
        color: $gray-100;
        transition: all 0.3s;
        font-weight: 400;
        text-transform: uppercase;
    }
    &-item a:hover {
        color: $secoundary;
    }
    &-item+&-item{
        padding: 0;
    }
    &-item+&-item::before {
        padding-right: 6px;
        padding-left: 6px;
        content: "-";
    }
    &-item.active {
        color: $dark;
    }
}

@include media-breakpoint-down(xl) {
    .breadcrumb{
        margin-bottom: 10px;
    }
}

@include media-breakpoint-down(sm) {
    .breadcrumb{
        display: none;
    }
}
