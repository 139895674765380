
// 
// page: faq
// 

.section-faq{
    position: relative;
    .faq-bg-image{
        position: absolute;
        left: 0;
        top: -12%;
        z-index: -1;
        transform: translateX(-50%);
    }
}

ul.faq-list{
    padding: 0;
    margin: 80px 0px;

    li{
        padding: 20px 0;
        margin: 0;
        border-bottom: 1px solid #e7e7e7;
        position: relative;
    }

    .collapse-inner{
        padding: 10px 0px 10px 60px;
    }
    li.black{
        background-color: $dark;
        border-color: #2b2b2b;
        &:before{
            content: "";
            position: absolute;
            top: 0px;
            bottom: -1px;
            right: -20%;
            left: -50vw;
            background-color: $dark;
            z-index: -1;
        }
        .collapse-inner p{
            color: rgba($white, 0.6);
        }
    }
}

[data-toggle="collapse"] {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: $dark;
    display: block;
    position: relative;
    padding: 14px 0px 14px 60px;
    border-bottom: 1px solid transparent;
    &:before {
        font-family: 'icomoon' !important;
        font-size: 16px;
        position: absolute;
        left: 25px;
        top: 18px;
        color: $secoundary;
        content: "\e914";
    }
}
[data-toggle="collapse"].collapsed {
    color: $black;
    &:before {
        color: $black;
        content: "\e918";
    }
    &:hover {
        color: $secoundary;
    }
}


li.black{
    [data-toggle="collapse"] {
        color: $white;
        display: block;
        position: relative;
        border-bottom: 1px solid transparent;
        &:before {
            color: $secoundary;
        }
    }
    [data-toggle="collapse"].collapsed {
        color: $white;
        &:before {
            color: $white;
        }
        &:hover {
            color: $secoundary;
        }
    }
}

.faq-dark{
    background-color: $dark;
    padding: 40px 0;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        top: 0px;
        bottom: -1px;
        right: -20%;
        left: -50vw;
        background-color: $dark;
        z-index: -1;
    }
    .faq-dark-bg{
        position: absolute;
        top: -70%;
        left: -50%;
    }
}

@include media-breakpoint-down(md) {
    [data-toggle="collapse"]{
        padding-right: 20px;
    }
    ul.faq-list .collapse-inner {
        padding: 10px 20px 10px 60px;
    }
}

@include media-breakpoint-down(sm) {
    ul.faq-list{
        margin: 20px 0;
    }
}