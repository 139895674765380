// 
// page: studio
// 

.section-studio-place{
    .section-images{
        position: relative;
        padding: 10% 0 0 4%;
    }

    .section-image-center{
        position: relative;
        .image-holder{
            padding-bottom: 100%;
        }
    }

    .section-image-top{
        position: absolute;
        z-index: 22;
        width: 33.33333%;
        left: 0;
        top: 0;
        .image-holder{
            padding-bottom: 140%;
        }
    }

    .section-image-bottom{
        position: absolute;
        z-index: 22;
        width: 33.33333%;
        left: 0;
        top: 80%;
        .image-holder{
            padding-bottom: 140%;
        }
    }

    .section-content{
        background-color: $white;
        padding: 20% 0% 5% 5%;
    }

    .section-scroll{
        right: 4%;
        top: 45%;
    }
}


.section-studio-idea{
    padding: 3% 0 14% 0;
    .section-bg{
        left: 0%;
        top: -8%;
        bottom: 0;
        width: 68.8%;
    }

    .section-mark{
        position: absolute;
        top: 22%;
        right: 0;
        width: 45%;
        img{
            display: block;
            width: 100%;
        }
    }

    .section-lead{
        padding-top: 5%;
    }
    
    .section-scroll{
        left: 4%;
        top: 30%;
        .arrow-title{
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }
}

.section-studio-team{
    .team-image{
        display: block;
        width: 100%;
    }
    padding: 4% 0;
    margin-top: 4%;
    .section-bg{
        left: 0%;
        top: 0;
        bottom: 0%;
        width: 30%;
    }
}


@include media-breakpoint-down(xl) {
    .section-studio-place{
        .section-image-bottom{
            top: 65%;
        }
    }

    .section-studio-idea {
        padding: 8% 0 14% 0;
    }
}


@include media-breakpoint-down(md) {
    .section-studio-place {
        margin-top: 5%;
        .section-content{
            padding: 5%;
        }
        .section-images{
            padding: 0;
        }
        .section-image-top{
            top: 0;
            left: 0;
            transform: none!important;
        }
        .section-image-bottom{
            top: auto;
            left: auto;
            right: 0;
            bottom: 0;
            transform: none!important;
        }
    }
    .section-studio-idea{
        padding: 40px 0;
        .section-bg{
            width: 100%;
            top: 0;
        }
        .section-mark{
            display: none;
        }
    }
    .section-studio-team{
        padding: 40px 0;
        margin-top: 0;
        padding-top: 0;
        .section-bg{
            display: none;
        }
    }
}












.studio-v1{
    .section-studio-place{
        padding-top: 5%;
    
        .section-images{
            position: relative;
            padding-left: 4%;
        }
    
        .si-1 .image-holder{
            padding-bottom: 90%;
        }
        
        .mansory-container{
            .mansory-item, .grid-sizer{
                width: 33.333%;
            }
            .mansory-item:nth-child(2){
                padding-top: 20%;
            }
            .mansory-item:nth-child(3){
                padding-top: 10%;
            }
        }
    
        
    }
    
    .section-studio-idea{
        padding: 8% 0;
        .section-bg{
            left: 30%;
            right: 0;
            top: -15%;
            bottom: 0;
        }
        .idea-images{
            img{
                display: block;
                width: 100%;
            }
            img:nth-child(2){
                transform: translateX(25%);
            }
        }
    
        .section-scroll{
            bottom: 11%;
        }
    }
    
    .section-studio-team{
        padding: 8% 0;
        margin-top: 8%;
        .section-bg{
            left: 60%;
            top: 0;
            right: 0;
            bottom: 30%;
        }
    }
}






