// 
// layout: offcanvas
//
.offcanvas {
    position: fixed;
    left: 37.5%;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: $black;
    transform: translate3d(0%, -100%, 0);
    z-index: 99;
    transition: all .4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10% 10% 40px 15%;
    overflow: hidden;

    .offcanvas-mark{
        position: absolute;
        z-index: 2;
        left: 0%;
        bottom: auto;
        top: 0;
        width: 48%;
        img{
            display: block;
            width: 100%;
            max-width: 545px;
            transform: translateX(-22%);
        }
    }

    ul.offcanvas-menu{
        opacity: 0;
        transform: translateX(50px);
        transition: all 0.3s 0.4s;
        position: relative;
        z-index: 44;
        padding: 0;
        margin: 0;
        > li a{
            font-family: 'Oswald', sans-serif;
            font-size: 48px;
            text-transform: uppercase;
            font-weight: 700;
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .offcanvas-data{
        text-align: right;
        opacity: 0;
        transition: all 0.3s 0.65s;
        transform: translateX(50px);
        .phone{
            font-family: 'Oswald', sans-serif;
            color: $white;
            font-size: 16px;
            font-weight: 300;
        }
        .email{
            color: $white;
        }
    }
}

.offcanvas.active{
    transform: translate3d(0, 0, 0);
    ul.offcanvas-menu{
        opacity: 1;
        transform: translateX(0px);
    }
    .offcanvas-data{
        transform: translateX(0px);
        opacity: 1;
    }
}

.body-scroll-above-hero{
    .offcanvas{
        left: 0;
    }
}

@include media-breakpoint-down(xl) {
    .offcanvas{
        .offcanvas-mark img{
            max-width: 310px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .offcanvas{
        left: 0;
        .offcanvas-mark{
            display: none;
        }
    }
}


@include media-breakpoint-down(sm) {
    .offcanvas{
        padding: 60px 5%;
        ul.offcanvas-menu > li a{
            font-size: 32px;
        }
        .offcanvas-data{
            margin-top: 20px;
        }
    }
}



