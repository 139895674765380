
.plyr__control--overlaid{
    background-color: transparent!important;
    
    &:after{
        content: "\e917";
        font-family: 'icomoon' !important;
        font-size: 44px;
        color: $white;
        transition: all 0.3s;
    }
    svg{
        display: none;
    }
}

.plyr__control--overlaid:hover{
    &:after{
        color: $secoundary;
    }
}

.plyr__poster{
    background-size: cover;
}