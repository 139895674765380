// 
// layout: footer
//

.header{
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    transition: all 0.3s;
    &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(-100%);
        transition: all 0.35s;
        background-color: $dark;
        z-index: -1;
    }
    .header-row{
        display: flex;
        align-items: center;
        padding: 5px 50px 5px 50px;
    }
    .header-brand{
        padding: 5px 0;
        margin-right: auto;
        opacity: 0;
        img{
            max-width: 50px;
            display: block;
            transform: rotate(180deg);
            transition: all 0.25s 0.15s;
        }
    }

    .header-phone{
        a {
            font-family: 'Oswald', sans-serif;
            color: $white;
            font-size: 16px;
            font-weight: 300;
        }
    }

    ul.header-lang{
        margin: 0px 25px;
        padding: 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        li a{
            font-family: 'Oswald', sans-serif;
            padding: 2px 18px;
            color: $white;
            font-size: 16px;
            font-weight: 300;
            text-transform: uppercase;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            &:hover{
                color: $secoundary;
            }
        }
    }

    ul.header-social-list{
        margin: 0px 25px;
        padding: 0;
        display: flex;
        > li{
            margin: 0px 10px;
        }
        > li > a{
            font-size: 16px;
            font-weight: 300;
            text-transform: uppercase;
            font-family: inherit;
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }
}

.page-scroll{
    .header{
        &:after{
            transform: translateY(0%);
        }
        .header-brand{
            opacity: 1;
        }
        .header-brand img{
            transform: rotate(0deg);
        }
    }
}

@include media-breakpoint-down(md) {
    .header{
        .header-row{
            padding: 5px 5%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .header {
        &:after{
            transform: translateY(0px);
        }
        .header-brand{
            opacity: 1;
        }
        .header-brand img{
            max-width: 40px;
        }
        .header-phone{
            display: none;
        }
        ul.header-social-list{
            margin: 0;
        }
        ul.header-lang{
            margin: 0 15px;
        }
    }
}



