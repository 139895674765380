// 
// component: Mansory
// 

.mansory-container{
    &:after{
        content: '';
        display: block;
        clear: both;
    }

    .mansory-item{
        float: left;
        padding: 0;
        img{
            display: block;
            width: 100%;
        }
    }

    .mansory-item--sm{
        .image-holder{
            padding-bottom: 50%;
        }
    }

    .mansory-item--md{
        .image-holder{
            padding-bottom: 70%;
        }
    }

    .mansory-item--lg{
        .image-holder{
            padding-bottom: 90%;
        }
    }

    .mansory-item--xl{
        .image-holder{
            padding-bottom: 110%;
        }
    }
    
    .mansory-item--xxl{
        .image-holder{
            padding-bottom: 140%;
        }
    }

    
    
    
    .grid-sizer {}
    
    .mansory-item-icon{
        position: absolute;
        z-index: 11;
        left: 0;
        right: 0;
        top: 0; 
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(20px);
        color: $white;
        font-size: 44px;
        opacity: 0;
        transition: all 0.3s;
    }

    .mansory-item:hover{
        .mansory-item-icon{
            opacity: 1;
            transform: translateY(0px);
        }
    }
}