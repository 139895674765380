// 
// layout: page
//

body{
    overflow-x: hidden;
}

.page{
    overflow: hidden;
}

.section{
    position: relative;
}

.section-bg{
    position: absolute;
    background-color: $dark;
    z-index: -1;
}

.section-lead{
    text-align: right;
    padding: 0% 0% 12% 18%;
}

.section-lead-left{
    text-align: left;
    padding: 0% 20% 12% 0%;
}

.section-title{
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 70px;
}

.section-content{
    padding: 5% 
}


.section-scroll{
    position: absolute;
}


@include media-breakpoint-down(xl) {
    .section-title{
        margin-bottom: 40px;
    }
    .section-lead {
        padding: 0% 0% 12% 10%;
    }
}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {
    .section-title{
        margin-bottom: 20px;
    }
    .section-lead{
        text-align: left;
        padding: 20px 0;
    }
    .section-scroll{
        display: none;
    }
}

@include media-breakpoint-down(sm) {}






