// 
// page: after & before
// 

.section-before-after{
    padding-top: 6%;
    .section-title{
        margin-bottom: 40px;
    }
    .h5{
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .section-image{
        margin: 10% 0;
    }

    ul{
        margin: 10% 0;
    }
}

.section-after{
    position: relative;
    .after-image{
        text-align: center;
    }
    .section-content{
        position: relative;
        padding: 10% 0;
    }
    .section-after-bg{
        position: absolute;
        left: -20%;
        right: -40vw;
        top: -5%;
        bottom: 0;
        background-color: $black;
        z-index: -1;
    }
}

@include media-breakpoint-down(lg) {
    .section-after {
        .section-content{
            padding: 10% 5%;
        }
    }
}