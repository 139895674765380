//
// Pagination
//
.pagination {
    display: inline-flex;
    border-radius: 0px;
    margin: 40px 0;
    li{
        padding: 5px;
    }
    li a {
        display: block;
        transition: all 0.3s;
    }
    li a.active {
    }
}

@include media-breakpoint-down(md) {
    .pagination{
        margin: 20px 0;
    }
}

