// 
// page: blog
// 



.blog-item{
    position: relative;
    overflow: hidden;
    .meta{
        font-weight: 300;
        margin-bottom: 10px;
        transition: all 0.3s;
        color: $secoundary;
    }
    .title{
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 25px;
        transition: all 0.3s;
    }

    .blog-item-image{
        display: block;
    }

    .image-holder{
        padding-bottom: 80%;
    }

    .blog-item-content{
        transition: all 0.3s;
        transform: translateY(0px);
        .inner{
            padding: 40px;
        }
    }
}

.blog-item:hover{
    .meta, .title, .title a{
        color: $secoundary;
    }
    .blog-item-content{
        transform: translateY(-10px);
    }
}

ul.blog-list{
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    > li{
        padding-bottom: 5%;
    }

    > li:nth-child(5n+1){
        flex: 0 0 45%;
        max-width: 45%;
        .blog-item{
            .image-holder{
                padding-bottom: 115%;
            }

            .blog-item-content{
                padding-left: 40%;
            }
        }
    }

    

    > li:nth-child(5n+2){
        flex: 0 0 35%;
        max-width: 35%;
        margin-left: 11.8%;
        .blog-item{
            transform: translateY(-15%);
            .image-holder{
                padding-bottom: 140%;
            }
        }
    }

    > li:nth-child(5n+3){
        flex: 0 0 35%;
        max-width: 35%;
        margin-left: 20%;
        .blog-item{
            .image-holder{
                padding-bottom: 140%;
            }
        }
    }

    > li:nth-child(5n+4), > li:nth-child(5n+5){
        .blog-item{
            .blog-item-content{
                position: absolute;
                z-index: 20;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: flex-end;
                pointer-events: none;
            }
            .title a, .meta, p{
                color: $white;
            }
        }
    }

    > li:nth-child(5n+4){
        flex: 0 0 28%;
        max-width: 28%;
        margin-left: 10%;
        .blog-item{
            transform: translateY(-25%);
            .image-holder{
                padding-bottom: 130%;
            }
        }
    }
    > li:nth-child(5n+5){
        flex: 0 0 28%;
        max-width: 28%;
        margin-left: 65%;
        padding-bottom: 0;
        margin-bottom: -15%;
        .blog-item{
            transform: translateY(-120%);
            .image-holder{
                padding-bottom: 130%;
            }
        }
    }
}


.section-blog-single{
    .mansory-container{
        .mansory-item, .grid-sizer{
            width: 25%;
        }
    }

    .blog-single-content{
        position: relative;
        .section-content{
            padding: 50% 0 30% 15%;
        }
        .blog-single-bg {
            position: absolute;
            left: 0;
            top: 20%;
            bottom: 0;
            width: 100%;
            background-color: $dark;
            z-index: -1;
        }
    }
}

@include media-breakpoint-down(xl) {
    .blog-item {
        .blog-item-content {
            .inner{
                padding: 20px;
            }
        }
        .title{
            font-size: 20px;
        }
    }

    .section-blog-single{
        padding-top: 5%;
        .blog-single-content .blog-single-bg{
            top: 15%;
        }
    }
}

@include media-breakpoint-down(lg) {
    ul.blog-list{
        > li:nth-child(5n+4), > li:nth-child(5n+5){
            .blog-item{
                .blog-item-content{
                    p{
                        display: none;
                    }
                }
            }
        }

        > li:nth-child(5n+1) .blog-item .blog-item-content{
            padding-left: 15%;
        }
    }

    .section-blog-single{
        .blog-single-content .blog-single-bg{
            top: 10%;
        }
    }
}


@include media-breakpoint-down(md) {
    .section-blog-single {
        .blog-single-content{
            margin-bottom: 40px;
            .section-content{
                padding: 40px 5%;
            }
        }
        .pager-container{
            padding-left: 10%;
        }

        .section-image .image-holder{
            padding-bottom: 70%;
        }
    }
}


@include media-breakpoint-down(sm) {
    ul.blog-list{
        display: block;
        > li, > li:nth-child(5n+1), > li:nth-child(5n+2), > li:nth-child(5n+3), > li:nth-child(5n+4), > li:nth-child(5n+5){
            display: block;
            flex: 0 0 100%;
            max-width: 100%;
            margin: 5% 0;
            .blog-item{
                transform: none!important;
                .blog-item-content{
                    pointer-events: all;
                    position: static;
                    padding: 0px;
                    p{
                        display: block;
                    }
                    .title a, .meta, p{
                        color: $black;
                    }
                }

                .blog-item-image{
                    .image-holder{
                        padding-bottom: 80%;
                    }
                }
                
            }
        }
    }
    .section-blog-single{
        .mansory-container{
            .mansory-item, .grid-sizer{
                width: 50%;
            }
        }
    }

}
