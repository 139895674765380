// 
// layout: article
// 
article{
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 30px;
    }
}


.section-article{
    padding-top: 8%;
    position: relative;
    .section-bg{
        left: 0%;
        right: 15%;
        top: -15%;
        bottom: 15%;
    }
}

.section-white{
    .section-content{
        background-color: $white;
    }
}

.section-black{
    .section-content{
        background-color: $dark;
    }
}

.section-article-black{
    padding-top: 10%;
    .section-content{
        padding-bottom: 30%;
    }
    .section-title{
        padding-top: 10%;
    }
}

.section-article-download{
    margin-top: 100px;
    padding: 45px 0px 85px 0px;
    .section-bg{
        left: 0%;
        right: 15%;
        top: 0%;
        bottom: 15%;
    }
    .download-title{
        color: $white;
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }
}

// 
// download item
// 
.download-item{
    font-size: 14px;
    padding: 20px 30px;
    padding-right: 140px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark;
    border-bottom: 1px solid rgba($white , 0.1);
    transition: all 0.3s 0s;
    
    .download-text{
        font-weight: 400;
        font-size: 14px;
        color: rgba($white, 0.6);
        transition: all 0.3s 0s;
    }
    
    .download-link{
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        right: 0;
        width: 120px;
        background-color: $black;
        color: $white;
        font-family: 'Oswald', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        transition: all 0.3s 0s;
    }
}

.download-item:hover{
    background-color: #424242;
    border-color: $black;
    .download-link{
        background-color: $secoundary;
        color: $white;
    }
}


@include media-breakpoint-down(xl) {
    .section-article{
        .section-bg{
            top: -12%;
            bottom: 8%;
        }
    }
}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {
    .section-article{
        padding: 0px 0;
        margin: 0px;
        .section-bg{
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
        }
    }

    .row-reverse-mobile{
        .col-image{
            order: 2;
        }
        .col-content{
            order: 1;
        }
    }

    .download-item{
        padding: 20px 10px;
    }
    .section-article-download .download-title{
        padding-left: 10px;
    }
}