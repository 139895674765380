html {
    box-sizing: border-box;
}

/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*/

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
}

.container-fluid{
    padding-left: 0;
    padding-right: 0;
    .row{
        margin-left: 0;
        margin-right: 0;
    }
    [class^="col-"]{
        padding-left: 0;
        padding-right: 0;
    }
}

ul.social-list{
    display: flex;
    li{
        margin: 0 10px;
    }
    li:first-child{
        margin-left: 0;
    }
    li a{
        font-size: 14px;
        display: block;
    }
}
