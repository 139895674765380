// 
// pager
// 

.pager-container{
    padding: 40px 0;
    max-width: 210px;
    margin-left: -20px;
}

.pager-flex {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    
    .pager-back {
        font-size: 11px;
        font-weight: 500;
        padding: 0 15px;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .pager-link {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        padding: 4px 10px;
        font-size: 44px;
        line-height: 1;
    }
}


@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(sm) {}