// 
// layout: footer
//

.footer{
    padding: 40px 0;
    ul, p{
        margin: 0;
        padding: 0;
    }

    .footer-container{
        position: relative;
    }

    .footer-brand{
        text-align: center;
        img{
            max-width: 125px;
        }
        .brand-title{
            font-family: 'Oswald', sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 28px;
            margin: 10px 0px 25px 0px;
        }
    }

    .footer-menu{
        padding: 25px 0;
    }
    ul.footer-menu-list{
        font-family: 'Oswald', sans-serif;
        display: flex;
        justify-content: center;
        > li{
            margin: 0px 25px;
        }
        > li > a{
            font-size: 16px;
            font-weight: 300;
            text-transform: uppercase;
            font-family: inherit;
        }
    }
    .footer-copyright{
        display: flex;
        justify-content: center;
        align-items: center;

        .comup{
            color: $gray-100;
            margin-left: 10px;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .footer-data{
        display: inline-flex;
        align-items: flex-end;
        position: absolute;
        top: 0;
        right: 0;
    }

    .footer-data-contact{
        border-right: 1px solid $gray-200;
        padding: 95px 35px 5px 0px;
        .phome, .email{
            display: block;
        }
        .phone{
            font-size: 16px;
            font-weight: 300;
            font-family: 'Oswald', sans-serif;
        }
    }

    .footer-data-socials{
        padding: 0px 0px 5px 35px;;
    }

    ul.footer-social-list{
        margin: 0;
        padding: 0;
        display: flex;
        li {
            margin: 0 10px;
        }
        >li a{
            font-size: 18px;
        }
    }
}


@include media-breakpoint-down(md) {
    .footer{
        .footer-data{
            position: static;
            display: block;
            justify-content: center;
            align-items: center;
        }

        ul.footer-menu-list > li{
            margin: 0 15px;
        }
        .footer-data-contact{
            padding: 10px 0;
            text-align: center;
            border:0px;
        }

        .footer-data-socials{
            padding: 10px 0;
        }

        ul.footer-social-list{
            justify-content: center;
            padding: 0 10px;
        }

        .footer-copyright{
            display: block;
            text-align: center;
        }
    }
}


@include media-breakpoint-down(sm) {
    .footer{
        .footer-menu{
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
        }
        ul.footer-menu-list{
            display: block;
            text-align: center;
        }
    }
}
