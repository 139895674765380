// 
// page: contact
//  
.contact-data {
    margin-bottom: 50px;
    p {
        margin: 0;
    }
}

.contact-hours {
    padding-top: 40px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: -40%;
        right: -20px;
        top: 0;
        height: 1px;
        background-color: $gray-200;
    }
    p {
        margin-bottom: 15px;
    }
    table tbody td:last-child {
        padding-left: 30px;
    }
}

.section-contact {
    position: relative;
    margin: 115px 0;
    padding: 60px 0;
    .section-bg {
        top: 0;
        bottom: 0;
        left: 0;
        width: 60%;
    }
    .section-content {
        position: relative;
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .form-title {
        margin-bottom: 50px;
    }
}

.g-map-container {
    padding-top: 240px;
}


@include media-breakpoint-down(xl) {
    .contact-hours:before {
        left: -25%;
    }
}

@include media-breakpoint-down(md) {
    .contact-hours{
        padding-top: 0;
        &:before{
            content: none;
        }
    }
    .section-contact{
        margin: 40px 0;
        padding: 40px 5%;
        .section-content{
            padding: 0;
        }
        .section-bg{
            width: 100%;
        }
    }
    .g-map-container{
        padding-top: 40px;
    }
}